<template>
    <div class="content">
      <div>
      <div>
        <b-row>
          <!-- <b-col cols="3">
            <base-input class="m-0 mr-4">
              <el-select
                class="select-primary pagination-select"
                v-model="status"
                placeholder="Status"
                >
                  <el-option
                      class="select-primary"
                      v-for="item in ['aktif','tidak aktif']"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
              </el-select>
            </base-input>
          </b-col> -->
          <b-col cols="4" class="mb-3 ml-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-input
                v-model="search"
                placeholder="Cari berdasarkan nama industri "/>
          </b-col>
        </b-row>
      
      <el-table
          :data="queriedData.filter(data => !search || data.company.toLowerCase().includes(search.toLowerCase()))"
          row-key="id"
          header-row-class-name="thead-light"
          @sort-change="sortChange"
          @selection-change="selectionChange"
      >
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            v-bind="column"
            >
          </el-table-column>

          <el-table-column min-width="180px"  label="Status">
            <div slot-scope="action">
              {{ aliasStatus(action.row.status) }}
            </div>
          </el-table-column>

          <el-table-column min-width="150px"  label="DIBUAT">
            <div slot-scope="action">
               {{ ConvertDateFunction(action.row.created_at) }}
            </div>
          </el-table-column>

          <el-table-column min-width="150px"  label="DIUBAH">
            <div slot-scope="action">
               {{ ConvertDateFunction(action.row.updated_at) }}
            </div>
          </el-table-column>
  
          <el-table-column min-width="100px"  label="Aksi" fixed="right" >
            <template slot-scope="action">
                <base-button
                @click="view(action.row.id)"
                type="primary"
                size="sm"
                icon
                >
                VIEW
                </base-button>
            </template>
          </el-table-column>
      </el-table>
      </div>
      <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
      <b-col cols="3" class="mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        placeholder="Per page"
        >
          <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
        </el-select>
        <div class="">
            <p class="card-category mt-3">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
    
            <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} rows selected
            </span>
            </p>
        </div>
      </b-col>
      <base-pagination
          class="pagination-no-border mt-3"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
      >
      </base-pagination>
      </div>
      </div>
    </div>
  </template>
  <script>
  import { Table, TableColumn, Select, Option } from "element-ui";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "../PaginatedTables/clientPaginationMixin";
  import swal from "sweetalert2";
  import { format } from 'date-fns';
  import users from "./dummyValidasiPendaftaran"
  
  export default {
    props: {
      tableData: {
        type: Array,
        required: true
      }
    },
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    mounted() {
      // console.log(this.dataList,'dipanggil dari table validasi');
    },
    data() {
      return {
        status:"",
        search:"",
        tableColumns: [
          {
            prop: "uid",
            label: "ID Pendaftaran",
            minWidth: 200,
          },
          {
            prop: "company",
            label: "Nama Industri",
            minWidth: 150,
          },
          {
            prop: "company_type",
            label: "Jenis Industri",
            minWidth: 150,
          },
          {
            prop: "province",
            label: "Provinsi",
            minWidth: 150,
          },
        ],
        selectedRows: [],
      };
    },
    methods: {
      ConvertDateFunction(theDate) {
        if (!theDate) {
          return ""
        } else {
          let result = format(new Date(theDate), 'dd-MM-yyyy HH:mm')
          return result
        }
      },
      aliasStatus(status) {
        let result = ""

        switch (status) {
          case 'WV':
            result = 'Waiting'
            break;
          case 'VA':
            result = 'Approved'
            break;
          case 'VR':
            result = 'Rejected'
            break;
          case 'WC':
            result = 'Waiting Change'
            break;

          default:
            break;
        }

        return result
      },
      paginationChanged(page) {
        this.pagination.currentPage = page;
      },
      handleLike(index, row) {
        swal.fire({
          title: `You liked ${row.name}`,
          buttonsStyling: false,
          type: "success",
          confirmButtonClass: "btn btn-success btn-fill",
        });
      },
      handleEdit(index, row) {
        swal.fire({
          title: `You want to edit ${row.name}`,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-info btn-fill",
        });
      },
      handleDelete(index, row) {
        swal
          .fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              this.deleteRow(row);
              swal.fire({
                title: "Deleted!",
                text: `You deleted ${row.name}`,
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            }
          });
      },
      deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      },
      selectionChange(selectedRows) {
        this.selectedRows = selectedRows;
      },
      view(id) {
          // this.$router.push("/adminValidasiPendaftaranView");
          // this.$router.push(`/adminValidasiPendaftaranView/${get.uid}`);
          this.$router.push(`/verifikasi/sparing/rincian/${id}`);

          // console.log("view");
      }
    },
  };
  </script>
  <style>
  .no-border-card .card-footer {
    border-top: 0;
  }

  .el-table__cell {
    background-color: #fff;
  }
  </style>
  