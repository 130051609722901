<template>
    <div>
        <h1 class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white" style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1">
        <h6 class="h2 text-white d-inline-block mb-0"><i class="ni ni-single-02 "></i> Validasi Pendaftaran</h6>
      </h1>
      
      <base-header class="pb-6">
        <b-row>
          <b-col xl="12" md="12">
            <b-card style="position: relative;overflow: hidden;">

              <h3>Cari Berdasarkan</h3>

              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">
                <!-- status pendaftaran -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                  <label><small>Status Pendaftaran</small></label>
                  <el-select class="select-primary pagination-select" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                  <el-option
                      class="select-primary"
                      v-for="item in selectOptionStatus"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                  >
                  </el-option>
                  </el-select>
                </div>

                <!-- Tanggal -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;">
                  <label><small>Tanggal</small></label>
                  <base-input name="Tanggal">
                    <flat-picker
                      placeholder="Filter Tanggal"
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggal"
                      style="background:white;"
                    />
                  </base-input>
                </div>


                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType" @change="resetIndustry()">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        filterable
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Kabupaten kota -->
                <!-- <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota">
                    <select
                      class="form-control"
                      v-model="form.umum.compCity"
                    >
                      <option
                        v-for="(option, idx) in provideSelect.kabupatenKota"
                        :key="idx"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </base-input>
                </div> -->

                <!-- Nama Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" >
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        filterable
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        :disabled="log_as !== 'admin'"
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                

               

                <!-- Titik Penaatan -->
                <div style="display: flex;flex-direction: column;width: 180px;">
                  <label><small>Titik Penaatan</small></label>
                  <base-input>
                      <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                        <el-option
                          v-for="option in listDataCompliencePoints"
                          :key="option.id"
                          :label="option.spot_name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
                
                
              </div>

              <base-button size="md" type="primary" @click="getBaseOnStatus"> Tampilkan </base-button>
              <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -100px;z-index: 50;opacity: 0.1;">
            </b-card>
          </b-col>
        </b-row>
    </base-header>

     
  
      <b-container fluid class="mt--6" >
        <b-row>
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
              <b-row>
                <b-col lg="6" cols="7">
                  <!-- <h6 class="surtitle">Overview</h6>
                  <h5 class="h3 mb-0">Total sales</h5> -->

                </b-col>
                <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                <button
                  class="btn text-white btn-sm"
                  style="background-color: #0a7641;"
                  @click="downloadExcelNow"
                >
                  <i class="fa fa-solid fa-file-excel mr-2" style="font-size: 20px;"></i>
                    Unduh Excel
                </button>
                </b-col>
              </b-row>
              </template>
         
          
               <section id="mytable">
                  <el-table
                    :data="listData"
                    row-key="id"
                    header-row-class-name="thead-light"
                  >

                    <el-table-column label="No" type="index" width="70" />


                    <el-table-column width="160px"  label="ID Pendaftaran" >
                      <div slot-scope="action">
                        {{ action.row.id }}
                      </div>
                    </el-table-column>

                    <el-table-column width="150px"  label="Nama Industri" >
                      <div slot-scope="action">
                        {{ action.row.comp_name }}
                      </div>
                    </el-table-column>

                    <el-table-column width="80px"  label="Titik Penaatan" >
                      <div slot-scope="action">
                        {{ action.row.total_station }}
                      </div>
                    </el-table-column>

                    <el-table-column width="150px"  label="Jenis Industri" >
                      <div slot-scope="action">
                        {{ action.row.comp_type_name }}
                      </div>
                    </el-table-column>

                    <el-table-column width="120px"  label="Provinsi" >
                      <div slot-scope="action">
                        {{ action.row.province_name }}
                      </div>
                    </el-table-column>

                    <el-table-column width="120px"  label="Kabupaten / Kota" >
                      <div slot-scope="action">
                        {{ action.row.kabkot_name }}
                      </div>
                    </el-table-column>

                    <!-- <el-table-column width="120px"  label="Nomor Telepon" >
                      <div slot-scope="action">
                        {{ action.row.company_phone }}
                      </div>
                    </el-table-column>

                    <el-table-column width="120px"  label="Email" >
                      <div slot-scope="action">
                        {{ action.row.company_email }}
                      </div>
                    </el-table-column> -->

                    <!-- <el-table-column min-width="130px"  label="Nama Penanggung Jawab" >
                      <div slot-scope="action">

                      </div>
                    </el-table-column> -->

                    


                    <el-table-column width="150px"  label="Waktu Dibuat">
                      <div slot-scope="action">
                        {{ formatDate(action.row.created_at) }}
                      </div>
                    </el-table-column>

                    <!-- <el-table-column min-width="200px"  label="Waktu Diubah">
                      <div slot-scope="action">
                      </div>
                    </el-table-column> -->

                    <el-table-column width="180px"  label="Status">
                      <div slot-scope="action">
                        <div :style="getStyleObject(action.row.status)" class="text-white p-1 text-center rounded">
                          {{ action.row.status }}
                        </div>
                      </div>
                    </el-table-column>


                    <el-table-column   label="AKSI">
                      <div slot-scope="action">
                          <base-button
                          @click="view(action.row.id)"
                          type="secondary"
                          size="sm"
                          icon
                          >
                          RINCIAN
                          </base-button>
                          <!-- edit -->
                          <!-- <base-button
                          @click="handleEdit(action.row)"
                          type="warning"
                          size="sm"
                          icon
                          >
                          EDIT
                          </base-button> -->
                      </div>
                    </el-table-column>
                  </el-table>

                  <div class="py-3">

                      <el-select
                        class="select-primary pagination-select mr-3"
                        v-model="pagination.perPage"
                        placeholder="Per page"
                        @change="changeSizePage()"
                        >
                        <el-option
                            class="select-primary"
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                      </el-select>

                      <small class="card-category">
                      Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                      </small>
                  </div>

                  <div style="display: flex;justify-content: flex-end;">
                    <base-pagination
                        class="pagination-no-border"
                        :current="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total"
                        @change="paginationChanged($event)"
                    >
                    </base-pagination>
                  </div>
                </section>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

    <!-- Loading Screen -->
    <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative;">
                <img src="img/brand/img-logo-color.png" alt="">
                <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>


    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px;overflow: scroll;">
            <div>
              <div>

                <div v-if="isLoading" class="text-center" style="margin-top: 200px;"><h1>Sedang Menyiapkan Data ...</h1></div>
                <div v-else style="padding: 10px; overflow-x: scroll" class="customTable" >
                  <!-- <table class="table mt-3" ref="exportable_table">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white" rowspan="3">Titik Penaatan 1</th>
                      <th class="text-white" rowspan="3">Titik Penaatan 2</th>
                    </tr>
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">ID Pendaftaran</th>
                      <th class="text-white">UID Logger</th>
                      <th class="text-white">Nama Kontak Person</th>
                      <th class="text-white">Nomor Handphone</th>
                      <th class="text-white">Email Kontak Person</th>
                      <th class="text-white">Password Akun</th>
                      <th class="text-white">Nama Perusahan</th>
                      <th class="text-white">Jenis Perusahaan</th>
                      <th class="text-white">Alamat Perusahaan</th>
                      <th class="text-white">Jalan</th>
                      <th class="text-white">Kabupten / Kota</th>
                      <th class="text-white">Provinsi</th>
                      <th class="text-white">Telepon</th>
                      <th class="text-white">Telepon Kantor</th>
                      <th class="text-white">Email Kantor</th>
                      <th class="text-white">Sumber Air Limbah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.company_name  }}</td>
                      <td>{{ item.message }}</td>
                      <td>{{ item.created_at }}</td>
                    </tr>
                  </tbody>
                </table> -->

                  <table class="fl-table mt-3" id="multiLevelTable" >
                    <thead>
                      <tr>
                          <th rowspan="3">NO</th>
                          <th rowspan="3">Nama Industri</th>
                          <th rowspan="3">Jenis Industri</th>
                          <th rowspan="3">Provinsi</th>
                          <th rowspan="3">Kab/Kot</th>
                          <th rowspan="3">Tanggal</th>
                          <th rowspan="3">Frekuensi Pembuangan</th>
                          <th style="background-color: #fbe5d6" colspan="14">Titik Penaatan A</th>
                      </tr>
                      <tr>
                          
                          <th rowspan="2" style="background-color: #fbe5d6;">Lintang</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Bujur</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Nama Badan Air <br> Penerima <br> Air Limbah</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">DAS</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Frekuensi Pembuangan <br> Air Limbah</th>
                          <th colspan="3" style="background-color: #fbe5d6;">Frekuensi Pembuangan <br> Air Limbah</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Lulus <br> Uji Konektivitas</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Nama Penyedia <br> Barang dan / atau jasa</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Waktu dibuat</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Waktu diubah</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Tanggal Tervalidasi</th>
                          <th rowspan="2" style="background-color: #fbe5d6;">Status Validasi</th>
                      </tr>
                      <tr>
                        <th style="background-color: #fbe5d6;">Perhari</th>
                        <th style="background-color: #fbe5d6;">Perminggu</th>
                        <th style="background-color: #fbe5d6;">Pertahun</th>
                      </tr>
                    </thead>
                    <tbody v-for="(item, idx) in listDataDownload">
                      <tr>
                        <td>{{ idx + 1 }}</td>
                        <td>{{ item.company_name }}</td>
                        <td>{{ item.company_type_name }}</td>
                        <td>{{ item.province_name }}</td>
                        <td>{{ item.regency_name }}</td>
                        <td>Waktu</td>
                        <td>{{ item.frekuensi_pembuangan_air_limbah }}</td>
                        <td>Lintang</td>
                        <td>Bujur</td>
                        <td>Nama Badan Air</td>
                        <td>DAS</td>
                        <td>{{ item.frekuensi_pembuangan_air_limbah }}</td>
                        <td>{{ item.frekuensi_per_hari }}</td>
                        <td>{{ item.frekuensi_per_minggu }}</td>
                        <td>{{ item.frekuensi_per_tahun }}</td>
                        <td>Lulus Uji Konektivitas</td>
                        <td>Nama Penyedia Barang</td>
                        <td>{{ formatDate(item.created_at) }}</td>
                        <td>Waktu diubah</td>
                        <td>Tanggal Tervalidasi</td>
                        <td>{{ getStatus(item.status) }}</td>
                      </tr>
                    </tbody>
                    <!-- <tbody>
                      <tr v-for="(item, idx) in listDataDownload.items">
                        <td>{{idx + 1 }}</td>
                        <td colspan="3">{{item.company_name }}</td>
                        <td colspan="2">{{formatDate(item.timestamp) }}</td>
                        <td colspan="1">{{item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan" }}</td>
                        <td colspan="1">{{item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.debit? item.debit.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.beban_cod? item.beban_cod.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.beban_tss? item.beban_tss.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.beban_nhn? item.beban_nhn.toFixed(5): "Tidak Diwajibkan"}}</td>
                      </tr>
                    </tbody> -->
                  </table>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="exportThisWithParameter('multiLevelTable', 'pendaftaran_sparing.xls')"
              :disabled="isLoading"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
    </div>
</template>
  
  <script>
  import API from '../../../../api/base_url'
  import { Select, Option, Table, TableColumn } from "element-ui";
  import BaseHeader from "@/components/BaseHeader";
  import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
  import ValidasiPendaftaran from '../../../Tables/AdminTable/ValidasiPendaftaran.vue';
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";

  // new
  import Modal from "@/components/Modal.vue";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
  import html2pdf from "html2pdf.js";
  import moment from 'moment'
  
  
  export default {
  components: {
    Modal,
    BaseHeader,
    DataSparingChart,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ValidasiPendaftaran
  },
  mounted() {
    this.log_as = localStorage.getItem("as");
    this.getData()
    this.getCompanyType()
    this.getCompanyName()
    this.getProvince()
  },
  data() {
    return {
      log_as: "",
      pdfName:"",
      listDataDownload: [],
      listDataCompliencePoints: [],
      filterStatusPendaftaran: "",
      selectOptionStatus: [
        {
          val:"",
          name:"Semua"
        },
        {
          val: "Menunggu Validasi",
          name:"Menunggu"
        },
        {
          val:"Diterima",
          name:"Disetujui"
        },
        {
          val:"Ditolak",
          name:"Ditolak"
        },
      ],
      userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      provinsi:[],
      kabupatenKota:[],
      regency:[]
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Minggu",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Bulan",
                  attributes:{
                    class:"btn w-75 btn-dark mb-5 btn-sm"
                  }
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes:{
                    class:"btn w-100 btn-danger btn-sm"
                  }
                },
              ],
              onClick:(index,fp)=>{
                fp.clear();

                if(index == 0){
                const startDate = moment().startOf('day');
                  const endDate = moment(startDate).endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                  
                }else if(index == 1){
                  const startDate = moment().startOf('day');
                  const endDate = moment(startDate).add(1, 'week').endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);

                 
                }else if(index == 2){
                  const startDate = moment().startOf('month');
                  const endDate = moment().endOf('month');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                }else{
                  fp.clear();
                }
              }
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal:false,
      showDownloadModalCSV:false,
      // end of filter
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
      },
      listData: [],
      keyword:"",
    };
  },
  
  methods: {
    getData() {
      this.isLoading = true
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      API.get(`sparing?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
        .then(({data}) => {
          if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }

            setTimeout(() => {
              this.isLoading = false
            }, 300);
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err);
        })
    },
    getCompanyType() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comp-types', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comps', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getProvince() {
    API.get(`provinces`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getMyCompliencePoint() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
       .then(({data:content}) => {
        this.userInput.titikPenaatan = ""
         this.listDataCompliencePoints = content.data
       })
       .catch((err) => {
         console.log(err);
       })
    },
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBaseOnStatus() {
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }

    const queryParams = this.queryParams();


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

  
   

     const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


    API.get(`sparing` , { params, headers})
      .then(({data}) => {
        if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.pagination.total = data.meta.pagination.total_items
            this.isLoading = false
          }
      })
      .catch((err) => {
        this.isLoading = false
        console.log(err);
      })
    },
    getStyleObject(val) {
        let backgroundColor;
        switch (val) {
          case "Menunggu Validasi":
            backgroundColor = "rgb(245 158 11)";
            break;
          case "Diterima":
            backgroundColor = "rgb(20 83 45)";
            break;
          case "Ditolak":
            backgroundColor = "#dc3545";
            break;
          default:
            backgroundColor = "gray";
        }

        return {
          backgroundColor,
          // Anda dapat menambahkan properti CSS lainnya di sini
        };
    },
    queryParams(){
      let tmpDate = this.userInput.tanggal.split(" to ");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      const queryParams = {
        // page: this.pagination.currentPage,
        // page_size: this.pagination.perPage,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };
      return queryParams
    },
    resetIndustry() {
      this.listDataCompliencePoints = []
      this.userInput.companyName = ""
      this.userInput.titikPenaatan = ""
    },
    changeSizePage() {
        this.getData()
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData()
    },
    getDataDownload() {
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      const queryParams = {
        is_export: true,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


        API.get(`sparing` , { params, headers})
          .then(({data}) => {
            if (data.data == null) {
                this.listDataDownload = []
                this.isLoading = false
              } else {
                this.listDataDownload = data.data
                this.isLoading = false
              }
          })
          .catch((err) => {
            this.isLoading = false
            console.log(err);
          })
    },
    downloadPDFNow() {
      this.getDataDownload()
      this.showDownloadModal = true      
    },
    print() {
      var element = document.getElementById("printBos");
      var opt = {
        margin: 0.5,
        filename: "myfile.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      }
      // New Promise-based usage:
      html2pdf().set(opt).from(element).save();
      Swal.fire('Data di Unduh','','success')
      this.showDownloadModal = false
    },
    downloadExcelNow() {
      this.getDataDownload()
      this.showDownloadModalCSV = true
    },
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
    },
    view(id) {
        this.$router.push(`/verifikasi/sparing/rincian/${id}`);
    }
  },
  };
  </script>
  
  <script setup>
let exportThisWithParameter = (() => {
    var base64;
    var format;
    var uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"  ><meta http-equiv=Content-Type content="text/html; charset=utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets> <x:ExcelWorksheet><x:Name>{worksheet}</x:Name> <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions> </x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook> </xml><![endif]--></head><body> <table>{table}</table></body></html>' 
        base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        },
        format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
        }
    return function (tableID, excelName) {
        tableID = document.getElementById(tableID)
        var ctx = { worksheet: excelName || 'Worksheet', table: tableID.innerHTML }
        //              window.location.href = uri + base64(format(template, ctx))
        var link = document.createElement("a");
        link.download = excelName;
        //link.charset = 'utf-8';
        link.href = uri + base64(format(template, ctx));
        link.click();
    }
})()

</script>
    
<style>
.headblue {
    background-color: #DEEBF7;
}

.customTable tr
 {
    text-align: center;
    border: 1px solid #000;
    color: black;
}
.customTable th
 {
    text-align: center;
    border: 1px solid #000;
    color: black;
    font-weight: 900;
    padding: 30px;
}

.customTable caption {
    background-color: #ccc;
}

.customTable .addHead {
    display: none;
}




.customTable .table-wrapper {
    margin: 10px 70px 70px;
    box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

.customTable .fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
}

.customTable .fl-table td,
.fl-table th {
    text-align: center;
    padding: 10px;
}

.customTable .fl-table td {
    border-right: 1px solid #000;
    font-size: 12px;
}

.customTable .fl-table thead th {
    color: black;
    /* background: #4FC3A1; */
}










/* Responsive */

/* @media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }

    .table-wrapper:before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }

    .fl-table thead th:last-child {
        border-bottom: none;
    }

    .fl-table thead {
        float: left;
    }

    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .fl-table td,
    .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }

    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }

    .fl-table tbody tr {
        display: table-cell;
    }

    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }

    .fl-table tr:nth-child(even) {
        background: transparent;
    }

    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tbody td {
        display: block;
        text-align: center;
    }
} */

</style>
    